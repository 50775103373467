<template>
  <div class="auth-layout">
    <div class="auth-container">
      <router-link
        :to="'/'"
        class="auth-logo"
      >
        <img
          src="@/assets/images/owl-logo.svg"
          alt="Owl Logo"
        >
      </router-link>
      <div class="auth-content">
        <router-view />
      </div>
      <div
        v-if="clientUrl"
        class="auth-footer"
      >
        <img
          :src="clientUrl"
          :alt="clientLogoAltText"
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AuthLayout',
  computed: {
    clientLogoAltText () {
      return `${this.$store.getters.generalSettings.customer_name} Logo` || 'Logo'
    },
    clientUrl () {
      return `/logos/${this.$store.getters.generalSettings.custom_logo || '/logos/clear.png'}`
    }
  },
  beforeCreate () {
    // TODO: https://owloutcomes.atlassian.net/browse/OWL-8407
    // for now, ensure clean start for auth
    document.body.removeAttribute('class')
  }
}
</script>

<style lang="scss">
@import './../../assets/styles/override.scss';
@import './../../assets/styles/mixins.scss';

$device-min-height: 620px;
$device-min-width: 355px;
$layout-padding: 10px;

.auth-layout {
  padding: $layout-padding;
  height: 100%;
  max-height: 100%;
  background-image: url(./../../assets/images/bg-pattern.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  display: flex;

  .auth-logo {
    margin: 10px auto 20px auto;

    img {
      width: 90px;
    }

    @media (min-device-height: $device-min-height) {
      margin: 20px auto 30px auto;

      img {
        width: 110px;
      }
    }
  }

  .auth-container {
    margin: auto;
    background: $white;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    border-radius: 20px;

    @media (min-device-height: $device-min-height) {
      height: calc(#{$device-min-height} - #{$layout-padding} * 2);
    }

    @media (min-device-width: $device-min-width) {
      width: calc(#{$device-min-width} - #{$layout-padding} * 2);
    }

    .auth-content {
      overflow: auto;
      padding: 0px 30px 0 30px;

      .get-code {
        height: 100%;

        form {
          display: flex;
          flex-direction: column;
        }
      }

      .log-in {
        display: grid;
      }
    }

    .help-text {
      font-family: MuseoSans_300;
      font-weight: 300;
      color: $inactive-text;
      text-align: center;
      line-height: normal;
      font-size: 16px;
      margin-top: 15px;

      a {
        color: $navy-blue;

        &:hover {
          color: #2068ac;
        }
      }
    }

    .legacy-link {
      margin-bottom: 0;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    h2 {
      font-size: 30px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: $navy-blue;
      font-family: MuseoSans;
      text-transform: capitalize;
    }

    form,
    .form-container {
      .form-check {
        text-align: left;
        padding-top: 5px;
        padding-bottom: 5px;
        input {
          margin-top: 3px;
        }
      }

      .form-group {
        input {
          border: 1px solid $cloudy-blue;
          box-sizing: border-box;
          font-weight: 100;
          color: $navy-blue;
          text-align: left;
          &:hover{
            border-color: $inactive-text;
          };
          &:focus{
            border-color: #2068AC;
          }
        }

        label {
          float: left;
          width: 100%;
          margin-bottom: 10px;
          text-transform: uppercase;
        }

        .error {
          padding-bottom: 0;
        }
      }

      label {
        opacity: 0.6;
        margin-bottom: 0;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1.5px;
        text-align: left;
        color: $deep-blue;
      }
    }

    .set-user-password {
      display: flex;
      flex-direction: column;
      min-height: 100%;
      justify-content: space-between;

      .title {
        font-size: 25px;
        font-size: 30px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.96;
        letter-spacing: normal;
        text-align: center;
        color: $navy-blue;
        text-transform: capitalize;
        margin-bottom: 30px;
      }

      .help-text {
        margin-bottom: 0;
      }

      .error {
        width: 100%;
      }

      .password-explanation {
        margin-top: 3px;
        box-sizing: border-box;
        font-family: MuseoSans_300;
        font-weight: 300;
        color: $inactive-text;
        text-align: left;
        font-size: 14px;
        line-height: 15px;
        display: inline-block;
      }

      .button-container {
        display: flex;
        justify-content: center;
        flex-grow: 1;

        button {
          margin-top: 15px;
          margin-bottom: 5px;
          align-self: center;
          text-transform: capitalize;
        }
      }
    }
  }
  .auth-footer {
    margin: 0 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #f7f8f9;

    img {
      margin: 0;
      max-width: 100%;
    }
  }
}
</style>
